.App {
  background-color: #fff;
  color: #8b8d90;
}

.step {
  width: 1438px;
  height: 900px;
  background-image: url("../../img/default/fade.png");
  background-repeat: no-repeat;
  background-position: top right;
}

.your-connect-kit {
  font-size: 14px;
  color: #000;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 1em;
}

.your-connect-kit.results {
  display: inline-block;
  border: 1px solid #000;
  border-radius: 5px;
  padding: 5px 15px;
  margin-bottom: 30px;
}

.form .form-label {
  color: #58595b;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.btn.btn-primary {
  background-color: #000;
  color: #fff;
  border-color: #000;
  font-size: 14px;
  font-weight: 700;
}

.btn.btn-secondary {
  background-color: #fff;
  color: #000;
  border-color: #fff;
  font-size: 14px;
  font-weight: 700;
}

#step3 .form-label {
  color: #000;
}

@media (prefers-color-scheme: dark) {
  .App {
    background-color: #000;
    color: #fff;
  }

  .App #deviceHeaderBanner #imgFadeDefault {
    display: none !important;
  }

  .App #deviceHeaderBanner #imgFadeDark {
    display: block !important;
  }

  #lf-logo {
    background-image: url("../../img/dark/lifefitness-logo.png");
  }

  .step {
    width: 1439px;
    height: 900px;
    background-image: url("../../img/dark/fade.png");
    background-repeat: no-repeat;
    background-position: top right;
  }

  .your-connect-kit {
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 1em;
  }

  /**/
  .your-connect-kit.results {
    display: inline-block;
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 5px 15px;
    margin-bottom: 30px;
  }

  .form .form-label {
    color: #fff;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  #step3 input.form-control {
    border: 1px solid #fff;
    background-color: #000;
  }

  .btn.btn-primary {
    background-color: #fff;
    color: #000;
    border-color: #fff;
    font-size: 14px;
    font-weight: 700;
  }

  .btn.btn-secondary {
    background-color: #000;
    color: #fff;
    border-color: #000;
    font-size: 14px;
    font-weight: 700;
  }

  #step3 .form-label {
    color: #fff;
  }

  .row.roi-progress-bar .col-lg-4 div {
    height: 4px;
    background-color: rgba(31, 36, 41, 1);
    padding: 0;
    border-radius: 3px;
  }
}

@media screen and (max-width: 991px) {
  .App {
    width: 100%;
    height: 100%;
    padding: 10px;
  }

  .App #deviceHeaderBanner #imgFadeDefault {
    display: block;
  }

  .App #deviceHeaderBanner #imgFadeDark {
    display: none;
  }

  .App #lf-logo {
    background-position-y: 0;
    padding-top: 65px;
  }

  .App.page-1,
  .App.page-2,
  .App.page-3 {
    background-image: none;
  }

  .App .step {
    width: 100%;
    height: 100%;
    background: none;
  }

  .App .step .main.form {
    width: 100%;
    padding-left: 0;
  }

  .App .step .main.form .lf-header {
    font-size: 32px;
  }

  .App .step .step2-subheader {
    margin-bottom: 0;
  }

  .App #deviceHeaderBanner {
    display: block;
    background-position-x: 0;
    background-position-y: -10px;
    background-size: 100%;
    background-repeat: no-repeat;
    height: 60vw;
    padding: 0;
    margin: 0;
  }

  .App.page-1 #deviceHeaderBanner {
    background-image: url("../../img/device/step-1.png");
  }

  .App.page-2 #deviceHeaderBanner {
    background-image: url("../../img/device/step-2.png");
  }

  .App.page-3 #deviceHeaderBanner {
    background-image: url("../../img/device/step-3.png");
  }

  .row > div {
    margin-bottom: 15px;
  }

  .row.next-button {
    margin-top: 20px !important;
  }

  .form .form-check:last-child {
    float: none;
    margin-left: 20px;
  }
}

@media screen and (max-width: 370px) {
  .App.page-3 .step .main.form .lf-header.results {
    font-size: 28px;
    line-height: 1em;
  }
}
