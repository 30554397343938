body {
  padding: 20px;
  background-color: #000 !important;
}

.App {
  width: 1440px;
  height: 900px;
  margin: 0 auto;
  font-family: "Nunito Sans", Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5em;
  background-repeat: no-repeat;
  background-position: top right;
  border-radius: 5px;
  padding: 0;
}

.App.page-1 {
  background-image: url("./assets/img/step-1.png");
}

.App.page-2 {
  background-image: url("./assets/img/step-2.png");
}

.App.page-3 {
  background-image: url("./assets/img/step-3.png");
}

#lf-logo {
  background-image: url("./assets/img/default/lifefitness-logo.png");
  background-position-y: 35px;
  background-repeat: no-repeat;
  background-size: 316px 50px;
  padding-top: 115px;
}

.lf-logo {
  width: 316px;
  padding-top: 35px;
  margin-bottom: 30px;
}

.lf-header {
  font-size: 32px;
  font-weight: 900;
  color: #00cb9a;
  margin-bottom: 30px;
}

.lf-header.results {
  font-size: 53px;
  text-transform: uppercase;
}

.form {
  width: 615px;
  padding-left: 65px;
}

.form .container {
  padding: 0;
}

.form .container .row {
  margin-bottom: 30px;
}

.form .container input.form-control,
.form .container select.form-select {
  height: 45px;
  background-color: #fafafa;
  font-size: 12px;
  color: rgba(139, 141, 144, 1);
  border-radius: 0px;
  border-bottom: 1px solid #8b8d90;
  border-top: 1px solid #fafafa;
  border-left: 1px solid #fafafa;
  border-right: 1px solid #fafafa;
}

.form .container select.form-select option {
  color: #000;
}

.form .container select.form-select option:first-child {
  color: rgba(139, 141, 144, 0.58);
}

.form .form-check {
  display: inline-block;
  margin-top: 1em;
}

.form .form-check:last-child {
  float: right;
}

.form .form-check .form-check-input:checked {
  background-color: #58595b;
  border-color: #000;
}

span.customer-tooltip {
  background-color: #f0f0f0;
  width: 12px;
  height: 12px;
  color: #444;
  display: inline-block;
  border-radius: 5px;
  font-size: 10px;
  text-align: center;
  margin-left: 10px;
  color: #58595b;
  line-height: 1.5em;
}

::placeholder,
::-ms-input-placeholder {
  color: rgba(139, 141, 144, 0.58);
}

.row.next-button {
  margin-top: 150px;
  text-align: right;
}

#step2 .row.next-button {
  margin-top: 87px;
}

#step3 {
  margin-top: 60px;
}

#step3 input.form-control {
  font-size: 28px;
  color: #00cb91;
  line-height: 38px;
  font-weight: 700;
  border: 1px solid #000;
  border-radius: 5px;
  height: 63px;
  background-color: #fff;
  text-align: center;
}

.row.next-button > div.col-lg-12 > div {
  border-top: 1px solid #c9cacc;
  padding-top: 20px;
}

.row.next-button .btn.btn-primary {
  width: 100%;
}

.row.next-button .btn.btn-secondary {
  width: 100%;
}
.row.next-button .btn.btn-secondary:active {
  background-color: transparent;
  border: none;
}

.row.next-button .btn.btn-primary.disabled {
  background-color: #aaa;
  border-color: #aaa;
}

.row.roi-progress-bar {
  margin-top: 80px;
}

.row.roi-progress-bar .col-lg-4 div {
  height: 4px;
  background-color: rgba(31, 36, 41, 0.2);
  padding: 0;
  border-radius: 3px;
}

.row.roi-progress-bar .col-lg-4.active div {
  background-color: #00cb91;
}

.tooltip-inner {
  text-align: left;
  width: auto;
  padding: 10px;
  max-width: max-content;
}

#step2 .form-label {
  margin-bottom: 0;
}

#step2 .form-label strong {
  font-size: 14px;
}

#deviceHeaderBanner {
  display: none;
}
